<template>
    <div>
        <p class="p-title">数据告警</p>
        <a-row style="margin-bottom:10px;">
          <a-form layout="inline">
            <a-form-item label="查询时间">
              <a-range-picker  @change="onChangeTime"/>
            </a-form-item>
          </a-form>
        </a-row>
        <a-table :columns="columns" :data-source="alarmData" :pagination="pagination">

        </a-table>
    </div>
</template>
 
<script>
import { queryCenterAlarmApi } from '@/api/home'
export default {
    data() {
        return{
            alarmData: [],
            columns: [],
            params: {
                page: 1,
                limit: 10,
                startTime: '',
                endTime: ''
            },
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
        }
    },
    mounted() {
        this.queryCenterAlarm()
    },
    methods: {
        queryCenterAlarm() {
            queryCenterAlarmApi({...this.params}, this.$route.query.moduleCode).then(res => {
                this.alarmData = res.result.content
                this.pagination.total = res.result.totalElements
            })
        },
        changePage(current, size){
            this.params.page = current
            this.params.limit = size
            this.queryCenterAlarm()
        },
        onChangeTime(e) {
            this.params.startTime = e[0]
            this.params.endTime = e[1]
            this.changePage(1, 10)
        },
    }
}
</script>

<style lang="scss">
  .p-title{
    font-weight: bolder;
    font-size: larger;
  }
</style>